import { runOtlp, registerCustomWebVitalMetric } from '@rtl_nl/rtl-otel-js-web';
import router from 'next/router';
import getConfigValue from 'utils/getConfigValue';
import useConsent, { ConsentCategory } from 'utils/hooks/useConsent';

let hasConsent = false;
export function Otel() {
  hasConsent = useConsent(ConsentCategory.FUNCTIONALLY_REQUIRED);

  if (hasConsent) {
    runOtlp({
      serviceName: 'buienradar-web',
      version: getConfigValue('buildId'),
      browserRoute: router.pathname,
      otlp: {
        url:
          getConfigValue('otelUrl') +
          'monitoring/opentelemetry-collector-frontend/receivers/otlphttp/v1/traces',
      },
    });
  }
  return null;
}

export function reportWebVitals(metric) {
  if (hasConsent) {
    registerCustomWebVitalMetric(metric);
  }
}
